<template>
    <div class="container mt-5">
      <CreateClassForm />
    </div>
  </template>
  
  <script>
  import CreateClassForm from '../components/CreateClassForm.vue';
  
  export default {
    components: { CreateClassForm },
  };
  </script>
  