<template>
    <div class="container mt-5">
      <div class="card-group">
        <div class="card">
            <img
                class="card-img-top"
                src="@/assets/thpt_hh.jpg"
                alt="Card image cap"
            />
            <div class="card-body">
                <h4 class="card-title">Trường THPT Hưng Hóa</h4>
                <p class="card-text"></p>
            </div>
        </div>
        <div class="card">
            <img
                class="card-img-top"
                src="@/assets/thpt_hh2.jpg"
                alt="Card image cap"
            />
            <div class="card-body">
                <h4 class="card-title"></h4>
                <p class="card-text"></p>
            </div>
        </div>
      </div>
      
    </div>
</template>

<script>

</script>
  