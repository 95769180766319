<template>
    <div class="container mt-5">
      <h3>Record Concentration Data</h3>
      <RecordConcentrationForm />
    </div>
  </template>
  
  <script>
  import RecordConcentrationForm from '../components/StudentDetail.vue';
  
  export default {
    components: { RecordConcentrationForm },
  };
  </script>
  