<template>
    <div class="container mt-5">
      <AddSubjectForm />
    </div>
  </template>
  
  <script>
  import AddSubjectForm from '../components/AddSubjectForm.vue';
  
  export default {
    components: { AddSubjectForm },
  };
  </script>
  