<template>
    <div class="container mt-5">
      <LoginForm />
    </div>
  </template>
  
  <script>
  import LoginForm from '../components/LoginForm.vue';
  
  export default {
    components: { LoginForm },
  };
  </script>
  