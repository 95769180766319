<template>
    <div class="container mt-5">
      <AddStudentForm />
    </div>
  </template>
  
  <script>
  import AddStudentForm from '../components/AddStudentForm.vue';
  
  export default {
    components: { AddStudentForm },
  };
  </script>
  