<template>
    <div class="container mt-5">
      <AddStudenttoClass />
    </div>
  </template>
  
  <script>
  import AddStudenttoClass from '../components/AddStudenttoClass.vue';
  
  export default {
    components: { AddStudenttoClass },
  };
  </script>